import { SgConnectEnvironment } from 'services/SgConnect/SgConnectEnvironment';

export enum Environment {
    Test = 'Test',
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Preview = 'Preview',
    Production = 'Production',
}

const allEnvs: Record<Environment, string[]> = {
    [Environment.Test]: [],
    [Environment.Local]: [
        'local.fr.world.socgen',
        'localhost',
    ],
    [Environment.Development]: [
        'myservices-backoffice-dev.sgmarkets.com',
        'serviceboard-backoffice-dev.sgmarkets.com',
        'serviceboard-backoffice-dev.azureedge.net',
        'sgmwebservboardbacoffdev.z16.web.core.windows.net',
    ],
    [Environment.Homologation]: [
        'myservices-backoffice-uat.sgmarkets.com',
        'serviceboard-backoffice-uat.sgmarkets.com',
        'serviceboard-backoffice-uat.azureedge.net',
        'sgmwebservboardbacoffuat.z16.web.core.windows.net',
    ],
    [Environment.Preview]: [
        'myservices-backoffice-preview.sgmarkets.com',
        'serviceboard-backoffice-preview.sgmarkets.com',
        'serviceboard-backoffice-preview.azureedge.net',
        'sgmwebservboardbacoffprv.z16.web.core.windows.net',
    ],
    [Environment.Production]: [
        'myservices-backoffice.sgmarkets.com',
        'serviceboard-backoffice.sgmarkets.com',
        'serviceboard-backoffice-prd.azureedge.net',
        'sgmwebservboardbacoffprd.z16.web.core.windows.net',
    ],
};

export const getEnvironment = (): Environment => {
    if ((window as any)?.__vitest_environment__) {
        return Environment.Test;
    }

    const env = Object.values(Environment)
        .find(x => allEnvs[x].includes(window.location.hostname));

    if (env) {
        return env;
    }

    throw new Error(`Can't find environment from hostname ${window.location.hostname}`);
};

interface IConfiguration {
    baseApiUrls: {
        serviceBoard: string;
        sgmSearch: string;
        sgConnect: string;
    };
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    sgConnectEnvironment: SgConnectEnvironment;
    cdnSharedBaseUrl: string;
    sgMonitoringRumUrl: string;
}

const defaultConfiguration = {
    baseApiUrls: {
        serviceBoard: 'https://sgmarkets-api-serviceboard-dev.fr.world.socgen',
        _serviceBoard: 'https://localhost:5000',
        sgmSearch: 'https://sgmarkets-api-search-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
        sgConnect: 'https://sgconnect-hom.fr.world.socgen',
    },
    clientId: '745e072c-e488-4be5-8cac-216cc7fabdde',
    authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    scope: [
        'mail',
        'openid',
        'profile',
        'api.serviceboard.backoffice.v3',
        'api.sg-markets-search.v1',
    ].join(' '),
    sgConnectEnvironment: SgConnectEnvironment.Homologation,
    cdnSharedBaseUrl: 'https://shared-uat.fr.world.socgen',
    sgMonitoringRumUrl: 'https://insight-realm-apm-hom.fleet.uat.sgmonitoring.dev.euw.gbis.sg-azure.com',
};

const getConfigurationPerEnvironment = (): IConfiguration => {
    const env = getEnvironment();

    switch (env) {
        case Environment.Test:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    serviceBoard: 'https://localhost',
                    sgmSearch: 'https://localhost',
                    sgConnect: 'https://localhost',
                },
            };

        case Environment.Local:
        case Environment.Development:
            return {
                ...defaultConfiguration,
            };

        case Environment.Homologation:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    serviceBoard: 'https://sgmarkets-api-serviceboard-uat.fr.world.socgen',
                    sgmSearch: 'https://sgmarkets-api-search-uat.sgmpinsight.dev.euw.gbis.sg-azure.com',
                    sgConnect: 'https://sgconnect-hom.fr.world.socgen',
                },
            };

        case Environment.Preview:
        case Environment.Production:
            return {
                ...defaultConfiguration,
                baseApiUrls: {
                    serviceBoard: 'https://sgmarkets-api-serviceboard.fr.world.socgen',
                    sgmSearch: 'https://sgmarkets-api-search-prd.sgmpinsight.prd.euw.gbis.sg-azure.com',
                    sgConnect: 'https://sso.sgmarkets.com',
                },
                clientId: '45e18f4b-c67b-439a-86ce-d8d67482f6f7',
                authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect/oauth2/authorize',
                sgConnectEnvironment: SgConnectEnvironment.Production,
                cdnSharedBaseUrl: 'https://shared.sgmarkets.com',
                sgMonitoringRumUrl: 'https://t-monitoring-rum.sgmarkets.com/rum/540a239031b64d7fbe34fe54fef041db',
            };
    }
};

export const configuration = getConfigurationPerEnvironment();
